import React from 'react';
import "./services.css";
import IMAGES from "../../images/index.js";

const Services = () => {
  return (
    <div className="services">
      <div className="row row-cols-1 row-cols-md-3 g-4 ">
  <div className="col">
    <div className="card h-100">
      <img src={IMAGES.Img14} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-warning">Website Development</h5>
        <p className="card-text">We specialize in designing various types of websites, including E-commerce, Portfolio, Social Networking, and Blog websites. Additionally, we offer hosting and maintenance services to ensure a comprehensive solution for our clients.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={IMAGES.Img9} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-warning">App Development</h5>
        <p className="card-text"> We design versatile mobile applications. Our team is adept at crafting solutions for every need. Whether it's a sleek e-commerce app, a dynamic social networking platform, or a robust productivity tool, we've got the expertise to bring your vision to life.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={IMAGES.Img12} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-warning">SEO</h5>
        <p className="card-text">With our Search Engine Optimization (SEO) expertise, we optimize your online presence to boost visibility, attract more organic traffic, and ultimately enhance your digital success. Let us elevate your website's ranking and drive meaningful results with our proven SEO strategies.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={IMAGES.Img11} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-warning">Graphics Design</h5>
        <p className="card-text">Our skilled graphic designers are adept at translating your ideas into stunning visuals that resonate with your audience. From logo design to brand identity, marketing materials to digital assets, we're here to elevate your brand with impactful graphics that stand out in today's competitive landscape. </p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={IMAGES.Img10} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-warning">Marketing Cosultancy</h5>
        <p className="card-text"> We offer more than just services; we provide strategic guidance to propel your brand forward. With our marketing consultancy expertise, we analyze market trends, identify opportunities, and develop tailored strategies to maximize your brand's reach and impact. From brand positioning to campaign optimization, we're committed to guiding you towards success in the ever-evolving landscape of marketing.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={IMAGES.Img8} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-warning">Animated Ads Design</h5>
        <p className="card-text"> We specialize in captivating audiences through dynamic storytelling. Our animated ads making services combine creativity and technology to craft visually stunning and engaging advertisements that leave a lasting impression. From eye-catching animations to compelling narratives, we tailor each ad to effectively convey your message and drive customer engagement.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={IMAGES.Img13} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-warning">Social Media Marketing</h5>
        <p className="card-text">We excel in harnessing the power of social media to elevate your brand presence. Our social media marketing services are designed to connect you with your target audience, drive engagement, and foster meaningful relationships. From strategic content creation to targeted advertising campaigns, we leverage the latest trends and insights to maximize your brand's impact across all major platforms.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card h-100">
      <img src={IMAGES.Img7} className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-warning">Social Media Account Handling</h5>
        <p className="card-text">We understand the importance of maintaining a strong and active presence on social media platforms. That's why we offer expert social media account handling services to ensure your brand's voice is consistently heard and engaged with online. From content creation and posting schedules to community engagement and reputation management, our dedicated team will manage every aspect of your social media accounts with professionalism and creativity.</p>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Services
