import React from "react";
import "./home.css";
import IMAGES from "../../images/index.js";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    const boxes = document.querySelectorAll(".box");

    window.addEventListener("scroll", checkBoxes);

    checkBoxes();

    function checkBoxes() {
      console.log(window.innerHeight);
      const triggerBottom = (window.innerHeight / 5) * 4;

      boxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top;

        if (boxTop < triggerBottom) {
          box.classList.add("show");
        } else {
          box.classList.remove("show");
        }
      });
    }
  }, []);

  return (
    <div id="homeMain">
      <div>
        <section id="home" className="first-section">
          <div className="left-section">
            <div className="hi">Empowering</div>
            <div className="name">
              <span className="seagreen">Your</span>
            </div>
            <div className="passion">Digital Presence...</div>
          </div>
          <div className="right-section">
            <img id="rightimage" src={IMAGES.Img3} alt="" />
          </div>
        </section>
      </div>
      <div className="aboutUs">
        <div className="aboutUsText">
          <div className="title">About Us</div>
          <div className="Description">
            Welcome to Brandporter, your trusted partner in digital marketing
            excellence. Founded in 2023, Brandporter is driven by a passion for
            innovation and a commitment to delivering results. We specialize in
            crafting bespoke strategies to empower businesses to thrive in the
            digital landscape. Our team consists of seasoned experts in digital
            marketing, each bringing a wealth of experience and expertise to the
            table. From SEO and social media marketing to content creation and
            paid advertising, we have the skills and knowledge to elevate your
            brand and expand your online presence. We believe in building strong
            relationships with our clients based on trust and quality service.
            Whether you're a small startup or a large corporation, we're here to
            help you succeed in the digital world. Let's work together to unlock
            your brand's full potential and achieve remarkable growth.
          </div>
        </div>
      </div>

      <section id="about" className="zero-section">
        <h1>How We Can Help Growing Your Brand...</h1>
        <div className="box show">Cost Effective Marketing </div>
        <div className="box">Reach New Customers </div>
        <div className="box">Reach Targeted Audiences </div>
        <div className="box">Increased Visibility and Traffic</div>
        <div className="box">Developing Brand Strategy</div>
        <div className="box">Reach Worldwide</div>
      </section>

      <div className="contactMain">
        <div className="contactHeading">Contact Us</div>
        <div className="contactHome">
          <div className="contactImg">
            <a
              href="https://www.facebook.com/profile.php?id=61556894011068"
              target="_blank"
            >
              <img id="imgHome1" src={IMAGES.Img6} alt="" />
            </a>
            <a href="https://www.instagram.com/brandporter__/" target="_blank">
              {" "}
              <img id="imgHome2" src={IMAGES.Img5} alt="" />
            </a>
            <a
              href="https://www.pinterest.com/341y376atd5ls6v783ds1j5dubvyid/"
              target="_blank"
            >
              <img id="imgHome1" src={IMAGES.Img4} alt="" />
            </a>
          </div>
          <div className="contactDetails">
            <div className="address">
              <b>Address [A]</b> : Brand Porter, Connaught Cir, Block-M,
              Connaught Place, New Delhi, Delhi-110001
            </div>
            <div className="address">
              <b>Address [B]</b> : Brand Porter, Pachoni Bora Chuck, Jorhat-1,
              Assam-785001{" "}
            </div>
            <div className="contactNo">
              {" "}
              <b>Contact No</b>: +91 6000017804
            </div>
            <div className="email">
              <b>E-mail</b>: business@brandporter.in
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
