const IMAGES = {
    Img1: require("./cross-N.png"),
    Img2: require("./ham2.png"),
    Img3: require("./brandporterHome.png"),
    Img4: require("./pinterest icon.png"),
    Img5: require("./insta icon.png"),
    Img6: require("./facebook icon1.png"),
    Img7: require("./account handling.jpg"),
    Img8: require("./animation.jpg"),
    Img9: require("./app design.jpg"),
    Img10: require("./consultancy.jpg"),
    Img11: require("./graphics design.jpg"),
    Img12: require("./seo.jpg"),
    Img13: require("./social media.jpg"),
    Img14: require("./web design.jpg"),

  };
  
  export default IMAGES;