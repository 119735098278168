import React from 'react';
import "./contact.css";
import IMAGES from "../../images/index.js";

const Contact = () => {
  return (
    <div>
      <div className="contactHead">
        <div className="contactHome">
          <div className="contactImg">
            <a
              href="https://www.facebook.com/profile.php?id=61556894011068"
              target="_blank"
            >
              <img id="imgHome1" src={IMAGES.Img6} alt="" />
            </a>
            <a href="https://www.instagram.com/brandporter__/" target="_blank">
              {" "}
              <img id="imgHome2" src={IMAGES.Img5} alt="" />
            </a>
            <a
              href="https://www.pinterest.com/341y376atd5ls6v783ds1j5dubvyid/"
              target="_blank"
            >
              <img id="imgHome1" src={IMAGES.Img4} alt="" />
            </a>
          </div>
          <div className="contactDetails">
            <div className="address">
              <b>Address [A]</b> : Brand Porter, Connaught Cir, Block-M,
              Connaught Place, New Delhi, Delhi-110001
            </div>
            <div className="address">
              <b>Address [B]</b> : Brand Porter, Pachoni Bora Chuck, Jorhat-1,
              Assam-785001{" "}
            </div>
            <div className="contactNo">
              {" "}
              <b>Contact No</b>: +91 6000017804
            </div>
            <div className="email">
              <b>E-mail</b>: business@brandporter.in
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
